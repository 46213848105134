import { Button, Input } from "@pankod/refine-antd";
import { useNavigation, useQueries, useQuery, useRouterContext } from "@pankod/refine-core";
import { useLocation, useParams, useSearchParams } from "@pankod/refine-react-router-v6";
import { passwordProvider } from "authProvider";
import { useState } from "react";

export const ResetPassword = () => {
  const { Link } = useRouterContext();
  const { list } = useNavigation();
  const params = useParams();
  const [searchParams, _] = useSearchParams();
  const [password, setPassword] = useState("");

  const submit = async () => {
    const email = params.email?.replaceAll(":", "");
    const id = params.id?.replaceAll(":", "");
    await passwordProvider.resetPassword({
      email: email || "",
      password,
      id: id || "",
    });
    const redirect = searchParams.get("redirect");

    if (redirect) {
      window.location.href = redirect;
    } else {
      list("orders");
    }
  };

  return (
    <div
      style={{
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Link to="/">
        <img
          style={{ margin: "20px auto" }}
          src="/images/logo.png"
          alt="Logo"
          width="30%"
        />
      </Link>
      <h1>Reset password</h1>
      <p>Please enter the new password you would like to use.</p>
      <div style={{ paddingTop: 45 }} />
      <Input size="middle" disabled={true} value={params.email} />
      <div style={{ paddingTop: 25 }} />
      <Input.Password
        size="middle"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div style={{ paddingTop: 35 }} />
      <Button
        type="primary"
        disabled={password.length < 6}
        onClick={() => submit()}
      >
        Submit
      </Button>
    </div>
  );
};

export default ResetPassword;
