import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FormInstance } from "antd/lib";
import { DisplayContract } from "./contract";

interface ContractProps {
  readonly formProps: FormInstance | undefined;
}

export const Contract = ({ formProps }: ContractProps) => {
  if (formProps) {
    const representativeName = formProps.getFieldValue("representativeName");
    const retailerName = formProps.getFieldValue("title");
    const retailerLegalName = formProps.getFieldValue("legalTitle");
    // The below line will error when it tries destructuring these fields if address is null or undefined.
    const { country, city, address2, postal_code } =
      formProps.getFieldValue("address");
    const addressParts = (address2 as string).split(",");
    const address = addressParts[0] + addressParts[1];

    if (
      !retailerName ||
      !retailerLegalName ||
      !address ||
      !city ||
      !country ||
      !postal_code ||
      !representativeName
    ) {
      return (
        <p>
          Expected Retailer Name, Retailer Legal Name, Address, City, Country
          and Representative Name to be filled out but something is missing.
          Please fix.
        </p>
      );
    }

    const contract = (
      <DisplayContract
        retailerName={retailerName}
        retailerLegalName={retailerLegalName}
        address={address}
        city={city}
        country={country}
        postal_code={postal_code}
        representativeName={representativeName}
      />
    );

    return (
      <PDFDownloadLink document={contract} fileName="carrybags_contract.pdf">
        {({ blob, url, loading, error }) => {
          if (url) {
            console.log("url:", url);
            return <iframe src={url} width="100%" height="100%"></iframe>;
          }
          if (error) {
            const errObj = {
              error: error,
              url,
              loading,
              retailerName,
              retailerLegalName,
              address,
              city,
              country,
              postal_code,
              representativeName,
            };
            console.log(errObj);
            return (
              <p>
                Sorry, unable to load contract due to network issues. Apologies
                for the inconvenience.{" "}
              </p>
            );
          }
          if (loading) {
            return <p>Loading...</p>;
          }
          return <p>Loading...</p>;
        }}
      </PDFDownloadLink>
    );
  } else {
    // React doesn't like conditional return, so just return an empty element if formProps is falsy.
    return (
      <div>Sorry, contract cannot be displayed due to browser issues.</div>
    );
  }
};
