import { useState, useEffect } from "react";
import { IResourceComponentsProps, useLogout } from "@pankod/refine-core";
import { Create, Form, Button, useForm } from "@pankod/refine-antd";
import { FormList } from "./form";
import { IOrder } from "interfaces";
import { dataProvider } from "dataProvider";
import dayjs from "dayjs";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
const USERS_DETAILS = "user details";

// This constant variable is used to refer to the order ID's storage location
// within the localStorage. Better than using 'different strings' which can have a typo.
export const cb_order_key = "cb_order_key";

const redirect = (resource: string) => {
  const redirectUrl = `/${resource}`;
  window.location.href = redirectUrl;
};

const onFinish = async (values: any) => {
  // Create an order, save the order in localStorage and then redirect to the list page
  // which will display a QR code with the order details in a modal.
  const result = await dataProvider.create({
    resource: "orders",
    variables: values,
    // Doesn't seem like metadata is used in function implementation.
    metadata: undefined,
  });

  // Save orderID in local storage and go to order's list page, which will show it.
  const orderID = result.docRef._key.path.segments[1];
  localStorage.setItem(cb_order_key, JSON.stringify(orderID));
  redirect("orders");
};

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  let { formProps, saveButtonProps, queryResult } = useForm<IOrder>({
    // warnWhenUnsavedChanges is set to false because, otherwise,
    // redirect will trigger a "Leave Page" pop up which we don't want
    // (even after order has been saved).
    warnWhenUnsavedChanges: false,
  });
  const [users, setUsers] = useState<any>({});
  const user = localStorage.getItem(USERS_DETAILS);
  const logout = useLogout();

  // Set user data if user is set as logged in through Firebase,
  // or logout if user is not logged in.
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const email = user.email?.toLowerCase();
      const q = query(collection(db, "stores"), where("email", "==", email));
      const result = await getDocs(q);
      if (result.docs.length > 0) {
        const doc = result.docs[0];
        const store = { ...doc, id: doc.id };
        setUsers(store);
      }
    } else {
      logout.mutate();
    }
  });

  if (!users && user) {
    let users1 = JSON.parse(user);
    console.log(users1);
    setUsers(users1);
  }

  if (!users && !user) {
    logout.mutate();
  }

  useEffect(() => {
    try {
      if (user) {
        let users1 = JSON.parse(user);
        console.log(users1);
        setUsers(users1);
      } else {
        if (!users) {
          logout.mutate();
        }
      }
    } catch (err) {
      console.log(err);
      if (!users) {
        logout.mutate();
      }
    }
  }, [user]);

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      headerButtons={<Button onClick={() => history.back()}>Back</Button>}
    >
      {users?.id && (
        <Form
          {...formProps}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            isActive: true,
            store: users?.id,
            bagCodes: [""],
            bags: 1,
            serviceType: "Normal",
            createDate: dayjs().toString(),
          }}
        >
          <FormList formProps={formProps} type="create" />
        </Form>
      )}
    </Create>
  );
};
