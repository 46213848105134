import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  useExport,
  useNavigation,
  HttpError,
  getDefaultFilter,
} from "@pankod/refine-core";
import common from "common";

import {
  List,
  Table,
  useTable,
  DateField,
  Popover,
  Space,
  Card,
  Form,
  Select,
  useSelect,
  Button,
  FormProps,
  Row,
  Col,
  CreateButton,
  Modal,
  Divider,
} from "@pankod/refine-antd";
import RefineReactRouter from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";

import { OrderStatus, OrderActions } from "components";
import { IOrder, IStore, IOrderFilterVariables } from "interfaces";
import { useEffect, useMemo, useState } from "react";
import { cb_order_key } from "./create";
import { dataProvider } from "dataProvider";
import QRCode from "react-qr-code";
export const USERS_DETAILS = "user details";

const tryGetDate = (dateString: any) => {
  const date = new Date(dateString);
  // Returns NaN if date is invalid,
  // and therefore lets us distinguish between a valid and an invalid date.
  const time = date.getTime();
  if (isNaN(time)) {
    return null;
  } else {
    return date;
  }
};

export const getDateWithoutArray = (label: string) => {
  const dateParts = label.split("/");
  const firstPart = dateParts[1];
  const secPart = dateParts[0];
  const thirdPart = dateParts[2];
  const newStr = `${firstPart}/${secPart}/${thirdPart}`;
  return new Date(newStr);
};

export const getDate = (record: any) => {
  if (
    record.orderStatusArray &&
    record.orderStatusArray.length > 0 &&
    record.orderStatusArray[0].label
  ) {
    const firstDate = record.orderStatusArray[0].label;
    // Have to perform some processing, because date is stored in dd/mm/yy format
    // but Javascript expects string in mm/dd/yy format.
    const dateParts = firstDate.split("/");
    const firstPart = dateParts[1];
    const secPart = dateParts[0];
    const thirdPart = dateParts[2];
    const newStr = `${firstPart}/${secPart}/${thirdPart}`;
    const newDate = tryGetDate(newStr);
    if (newDate) {
      return newDate;
    } else {
      return tryGetDate(firstDate);
    }
  } else if (record.createDate) {
    return tryGetDate(record.createDate);
  } else {
    return null;
  }
};

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  let user = localStorage.getItem(USERS_DETAILS);
  let users: any = user ? JSON.parse(user) : {};
  const { tableProps, sorter, searchFormProps, filters } = useTable<
    IOrder,
    HttpError,
    IOrderFilterVariables
  >({
    resource: "orders",
    initialFilter: [
      {
        field: "store",
        operator: "eq",
        value: users?.id,
      },
    ],
    onSearch: (params) => {
      const filters: any = [];
      const { orderStatus, isPaid, order } = params;
      filters.push({
        field: "orderStatus",
        operator: "in",
        value: orderStatus
          ? orderStatus == "Not Delivered"
            ? ["Out for Delivery", "Order Packaged", "Order Received"]
            : [orderStatus]
          : "",
      });
      filters.push({
        field: "id",
        operator: "id",
        value: order,
      });
      filters.push({
        field: "isPaid",
        operator: "boolean",
        value: isPaid,
      });
      return filters;
    },
  });

  const t = useTranslate();
  const { show, edit } = useNavigation();
  const { Link } = RefineReactRouter;
  const { isLoading, triggerExport } = useExport<IOrder>({
    sorter,
    filters,
    pageSize: 50,
    maxItemCount: 50,
    mapData: (item: any) => {
      return {
        id: item.id,
        paymentConfirmation: item.paymentConfirmation,
        serviceType: item.serviceType,
        products: item.products,
        servicePrice: item.total || item.servicePrice,
        employeeID: item.employeeID,
        store: item.store,
        bags: item.bags,
        logisticCompanyProvider: item.logisticCompanyProvider,
        isPaid: item.isPaid,
        logisticConfirmationNumber: item.logisticConfirmationNumber,
        orderStatus: item.orderStatus,
        currencySymbol: item.currencySymbol,
      };
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
      {/* <ExportButton onClick={triggerExport} loading={isLoading} /> */}
    </Space>
  );

  // Below variables are for order modal.
  const [openOrder, setOpenOrder] = useState<any>(null);
  const [store, setStore] = useState<any>(null);

  // Close order and remove the order ID from local storage.
  // (If order ID is not removed, then modal will keep appearing after every refresh.)
  const closeOrder = () => {
    localStorage.removeItem(cb_order_key);
    setOpenOrder(null);
    setStore(null);
  };

  // Load the order from localStorage if there is an order ID saved there.
  useEffect(() => {
    const showOrder = async () => {
      const orderCookie = localStorage.getItem(cb_order_key);
      if (orderCookie) {
        try {
          const orderID: string = JSON.parse(orderCookie as string);
          const orderResult = await dataProvider.getOne({
            resource: "orders",
            id: orderID,
            metadata: undefined,
          });
          // Load store as well, because QR Code takes the store's data as text.
          const storeResult = await dataProvider.getOne({
            resource: "stores",
            id: orderResult.data.store,
            metadata: undefined,
          });
          setOpenOrder(orderResult.data);
          setStore(storeResult);
        } catch (e) {
          localStorage.removeItem(cb_order_key);
          closeOrder();
        }
      }
    };
    showOrder();
  }, []);

  return (
    <>
      <Modal
        title={"Scan QR Code"}
        open={openOrder}
        onOk={() => closeOrder()}
        onCancel={() => closeOrder()}
        afterClose={() => closeOrder()}
      >
        <div style={{ textAlign: "center" }}>
          <Divider style={{ margin: 8 }} />
          {openOrder && (
            <QRCode
              value={`${openOrder.orderNumber}`}
              size={400}
              style={{ width: "100%" }}
            />
          )}
          <Divider style={{ margin: 8 }} />
          <p style={{ fontSize: 24, fontWeight: 600, color: "#ff4d4f" }}>
            Order ID #{openOrder === null ? "" : openOrder.orderNumber}
          </p>
        </div>
      </Modal>
      <Row gutter={[16, 16]}>
        <Col
          xl={6}
          lg={24}
          xs={24}
          style={{
            marginTop: "52px",
          }}
        >
          <Card title={t("orders.filter.title")}>
            <Filter formProps={searchFormProps} filters={filters || []} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            headerProps={{
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} rowKey="id">
              {/* <Table.Column dataIndex="id" title="ID" align="center" /> */}
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("orders.fields.orderNumber")}
                render={(value, record: any) => (
                  <Popover title={record.orderNumber} trigger="hover">
                    <Button onClick={() => show("orders", value)} type="link">
                      Order ID
                    </Button>
                  </Popover>
                )}
              />
              <Table.Column<IOrder>
                key="orderStatus"
                dataIndex={"orderStatus"}
                title={t("orders.fields.status")}
                render={(value) => {
                  return <OrderStatus status={value} />;
                }}
                // sorter
              />
              <Table.Column
                align="right"
                key="servicePrice"
                dataIndex="total"
                title={"Service Price"}
                render={(value, record: any) => {
                  // Javascript stores 0 as falsy, so render if value is truthy
                  // or if value is 0.
                  // Record gives access to other fields in the object.
                  return value || value === 0
                    ? `${record.currencySymbol}${value}`
                    : "";
                }}
              />
              <Table.Column
                align="right"
                key="serviceType"
                dataIndex="serviceType"
                title={"Service Type"}
              />
              <Table.Column
                key="store.id"
                dataIndex={"store"}
                title={t("orders.fields.store")}
                render={(value) => (
                  <Popover title={value} trigger="hover">
                    <Button type="link">Store ID</Button>
                  </Popover>
                )}
              />
              <Table.Column
                key="user.fullName"
                dataIndex={"user"}
                title={t("orders.fields.user")}
                render={(value) => (
                  <Popover title={value} trigger="hover">
                    <Button type="link">User ID</Button>
                  </Popover>
                )}
              />
              <Table.Column<IOrder>
                key="products"
                dataIndex="products"
                title={t("orders.fields.products")}
                render={(_, record) => (
                  <Popover title="Products" trigger="hover">
                    {t("orders.fields.itemsAmount", {
                      amount: record?.products?.length,
                    })}
                  </Popover>
                )}
              />
              <Table.Column
                key="createDate"
                dataIndex="createDate"
                title={"Order Date"}
                render={(value, record: any) => {
                  const date = getDate(record);
                  if (date) {
                    return <DateField value={date} format="LL" />;
                  } else if (record.createDate) {
                    return <DateField value={value} format="LL" />;
                  } else {
                    return <>No date</>;
                  }
                }}
                // sorter
              />
              <Table.Column<IOrder>
                fixed="right"
                title={t("table.actions")}
                dataIndex="actions"
                key="actions"
                align="center"
                render={(_value, record) => <OrderActions record={record} />}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();
  const { formProps, filters } = props;
  let user = localStorage.getItem(USERS_DETAILS);
  let users: any = user ? JSON.parse(user) : {};
  const { selectProps: orderSelectProps } = useSelect<IStore>({
    resource: "orders",
    optionLabel: "orderNumber",
    optionValue: "orderNumber",
    defaultValue: getDefaultFilter("order.orderNumber", filters),
    filters: [
      {
        field: "store",
        operator: "eq",
        value: users?.id,
      },
    ],
  });
  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "id",
    defaultValue: getDefaultFilter("store.id", filters),
  });

  const { selectProps: userSelectProps } = useSelect<IStore>({
    resource: "users",
    optionLabel: "id",
    defaultValue: getDefaultFilter("user.id", filters),
  });

  const createdAt = useMemo(() => {
    const start = getDefaultFilter("createdAt", filters, "gte");
    const end = getDefaultFilter("createdAt", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        q: getDefaultFilter("q", filters),
        store: getDefaultFilter("store.id", filters),
        user: getDefaultFilter("user.id", filters),
        status: getDefaultFilter("status.text", filters, "in"),
        createdAt,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Order Status"} name="orderStatus">
            <Select
              options={[
                { label: "Delivered", value: "Delivered" },
                { label: "Not Delivered", value: "Not Delivered" },
                { label: "Out for Delivery", value: "Out for Delivery" },
                { label: "Order Packaged", value: "Order Packaged" },
                { label: "Order Received", value: "Order Received" },
              ]}
              allowClear
              placeholder={t("orders.filter.status.placeholder")}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Paid"} name="isPaid">
            <Select
              options={[
                { label: "Done", value: "true" },
                { label: "Not Done", value: "false" },
              ]}
              allowClear
              placeholder={"Payment Status"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Order"} name="order">
            <Select
              // {...orderSelectProps}
              options={orderSelectProps.options}
              allowClear
              placeholder={"Search Orders"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("orders.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
