import { AuthProvider } from "@pankod/refine-core";
import { notification } from "@pankod/refine-antd";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";
import bcrypt from "bcryptjs-react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export const TOKEN_KEY = "auth";
export const USERS = "user";
export const USERS_DETAILS = "user details";

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const loginEmail = email.toLowerCase();
      const q = query(
        collection(db, "stores"),
        where("email", "==", loginEmail)
      );
      const querySnapshot: any = await getDocs(q);
      if (querySnapshot?.docs?.length == 0) {
        throw new Error(`No account with email ${email} found.`);
      }
      const userSnapshot = querySnapshot.docs[0];
      let userData = userSnapshot.data();
      const isPasswordCorrect = await bcrypt.compare(
        password,
        userData.password
      );
      if (!isPasswordCorrect) {
        throw new Error("Sorry, incorrect password.");
      }
      return querySnapshot.forEach((doc: any) => {
        let userData = doc.data();
        if (userData.isActive) {
          userData["id"] = doc.id;
          localStorage.setItem(TOKEN_KEY, `${userData.id}`);
          localStorage.setItem(USERS, `${JSON.stringify(userData)}`);
          localStorage.setItem(USERS_DETAILS, `${JSON.stringify(userData)}`);
        } else {
          console.log(userData);
          throw new Error(
            "Your account is currently in the queue for us to review and approve. Please contact us at support@wecarrybags.co.uk if there are any issues."
          );
        }
      });
    } catch (err: any) {
      switch (err.message) {
        case "Firebase: Error (auth/user-not-found).":
          throw new Error(
            "User not found. Please check your email again or sign up."
          );
        case "Firebase: Error (auth/wrong-password).":
          throw new Error(
            "Incorrect password. Please try typing your password again or reset it using the 'Forgot password?' link."
          );
        default:
          console.log(err.message);
          throw new Error(err.message);
      }
    }
  },
  register: async (values) => {
    try {
      const q = query(
        collection(db, "stores"),
        where("email", "==", values.email)
      );
      const querySnapshot: any = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        throw new Error("Sorry. User with this email already exists.");
      }
      notification.info({
        message: "",
        description: "Please wait while we process your data.",
      });
      // Send confirmation email.
      try {
        const managementEmail = values.email;
        const representativeEmail = values.representativeEmail;
        const retailerName = values.title;
        const retailerLegalName = values.legalTitle;
        const representativeName = values.representativeName;
        const { country, city, address2, postal_code } = values.address;
        const addressParts = (address2 as string).split(",");
        const address = addressParts[0] + addressParts[1];
        const params = {
          managementEmail,
          representativeEmail,
          retailerName,
          retailerLegalName,
          representativeName,
          country,
          city,
          postalCode: postal_code,
          address,
        };

        await axios.post(
          "https://backend.wecarrybags.co.uk/api/v1/partner/email-contract",
          params
        );
      } catch (err) {
        console.log("email err", err);
      }

      const salt = await bcrypt.genSalt(9);
      const hashedPassword = await bcrypt.hash(values.password, salt);
      const dbRef = collection(db, "stores");
      const id = uuidv4();
      const normalisedEmail = values.email.toLowerCase();
      await addDoc(dbRef, {
        ...values,
        email: normalisedEmail,
        uid: id,
        id: id,
        type: "vendor",
        isActive: false,
        createAt: String(new Date()),
        updateAt: String(new Date()),
        password: hashedPassword,
      });
      notification.success({
        message: `Registered account ${normalisedEmail}`,
        description: "Thank you for registering with CarryBags!",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message || JSON.stringify(error),
      });
      return Promise.reject(error);
    }
  },
  updatePassword: async () => {
    // Firebase doesn't allow updating password.
    notification.success({
      message: "Updated Password",
      description: "Password updated successfully",
    });
    return Promise.resolve();
  },
  forgotPassword: async ({ email }) => {
    try {
      const loginEmail = email.toLowerCase();
      const q = query(
        collection(db, "stores"),
        where("email", "==", loginEmail)
      );
      const querySnapshot: any = await getDocs(q);
      if (querySnapshot?.docs?.length == 0) {
        throw new Error(`No account with email ${email} found.`);
      }
      const userSnapshot = querySnapshot.docs[0];
      const userData = userSnapshot.data();
      const body = {
        email: loginEmail,
        id: userData.id,
      };
      await axios.post(
        "https://backend.wecarrybags.co.uk/api/v1/partner/get-password-reset-link",
        body
      );
      notification.success({
        message: "Reset Password",
        description: `Reset password link sent to "${email}"`,
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USERS);
    localStorage.removeItem(USERS_DETAILS);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const user = localStorage.getItem(USERS);
    if (!user) {
      return Promise.reject();
    }
    let users = JSON.parse(user);
    const q = query(collection(db, "stores"), where("uid", "==", users.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let userData: any = { ...doc.data(), id: doc.id };
      localStorage.setItem(USERS_DETAILS, `${JSON.stringify(userData)}`);
      return Promise.resolve({
        id: userData.id,
        name: userData.title,
        avatar: userData.avatar,
      });
    });
  },
};

type resetPasswordParams = {
  email: string;
  password: string;
  id: string;
};

export const passwordProvider = {
  resetPassword: async ({ email, password, id }: resetPasswordParams) => {
    const q = query(collection(db, "stores"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot?.docs?.length == 0) {
      throw new Error(`No account with email ${email} found.`);
    }
    const userSnapshot = querySnapshot.docs[0];
    const userData = userSnapshot.data();
    if (userData.id !== id) {
      throw new Error("Invalid link.");
    }

    const salt = await bcrypt.genSalt(9);
    const hashedPassword = await bcrypt.hash(password, salt);
    const userRef = doc(db, "stores", userSnapshot.id);
    await updateDoc(userRef, {
      password: hashedPassword,
    });
  },
};
