import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// We need to make sure these files are available at these paths in each project
// that includes this file.
const RUBIK_PATH = "/Rubik/Rubik-VariableFont_wght.ttf";
const FIRST_BACKGROUND_PATH = "/contract_bg/first.png";
const DEFAULT_BACKGROUND_PATH = "/contract_bg/default.png";

// Create styles
Font.register({
  family: "Rubik",
  src: RUBIK_PATH,
  format: "truetype",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    fontFamily: "Rubik",
  },
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  contractTitle: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
    color: "#fac729",
    fontSize: 12,
  },
  nonBindingTitle: {
    textAlign: "center",
    margin: 40,
    marginTop: 0,
    marginBottom: 30,
    fontSize: 28,
  },
  subheading: {
    textAlign: "center",
    margin: 100,
    marginTop: 0,
    marginBottom: 16,
    fontSize: 11,
  },
  textBody: {
    textIndent: 8,
    marginLeft: 40,
    marginRight: 40,
    textAlign: "justify",
    fontSize: 14,
    lineHeight: 1.2,
    marginTop: 1,
    marginBottom: 1,
  },
  textBodyWithBottomMargin: {
    marginLeft: 40,
    marginRight: 40,
    textAlign: "justify",
    fontSize: 14,
    marginTop: 1,
    marginBottom: 30,
  },
  signingArea: {
    flexDirection: "row",
  },
  signingText: {
    marginLeft: 40,
    marginBottom: 20,
    fontSize: 14,
    fontWeight: "bold",
  },
  footer: {
    fontSize: 12,
    color: "#A9A9A9",
    transform: "rotate(90deg)",
    transformOrigin: "100% 40%",
  },
});

interface IContract {
  readonly retailerName: string;
  readonly retailerLegalName: string;
  readonly address: string;
  readonly city: string;
  readonly country: string;
  readonly postal_code: string;
  readonly representativeName: string;
}

// Create Document Component
export const DisplayContract = ({
  retailerName,
  retailerLegalName,
  address,
  city,
  country,
  postal_code,
  representativeName,
}: IContract) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={FIRST_BACKGROUND_PATH} style={styles.pageBackground} />
        <Text style={styles.contractTitle}>CONTRACT</Text>
        <Text style={styles.nonBindingTitle}>
          Non-binding Service Provider Contract between CarryBags Limited UK and{" "}
          {retailerLegalName}
        </Text>
        <Text style={styles.subheading}>
          Paternership Agreement between CarryBags Limited Company and{" "}
          {retailerLegalName}
        </Text>
        <Text style={styles.subheading}>
          In this contract, {retailerLegalName} has been referred to as{" "}
          {retailerName}.
        </Text>
        <Text style={styles.textBody}>
          This Partnership Agreement ("Agreement") is made and entered into as
          of {new Date().toLocaleDateString("en-CA")} by and between CarryBags
          Limited Company ("CarryBags"), having its principal place of business
          at The Enterprise Hub, 5 Whitefriars Street, Coventry, UK, and{" "}
          {retailerName}, a corporation having its principal place of business
          at {address}, {city}, {country}.
        </Text>
        <Text style={styles.textBody}>
          WHEREAS CarryBags provides delivery services for shopping bags and, as
          well as a software solution that enables delivery to various locations
          and WHEREAS {retailerName} wishes to partner with CarryBags to provide
          delivery services of shopping bags to its cursomters, as well as
          access to CarryBags' software solution.
        </Text>
        <Text style={styles.textBody}>
          NOW, THEREFORE, the parties agree as follows:
        </Text>
        <Text style={styles.textBody}>
          Services: CarryBags shall provide delivery services of shopping bags,
          as well as access to its software solution ("services") to{" "}
          {retailerName} in accordance with the terms of this Agreement. The
          Services will be available to {retailerName} shoppers at the point of
          sale in {retailerName}'s store located at {address}, {city}, {country}
          . {retailerName} agrees to offer and sell CarryBags' services to its
          shoppers upon request.
        </Text>
        <Text style={styles.textBody}>
          Payment: CarryBags will take payment from its users over the
          software/mobile app/web app. Any other Payment shall be discussed
          separately.
        </Text>
        <Text style={styles.textBody}>
          Term and Termination: This Agreement shall have 2 years duration until
          terminated by either party. Duration could be auto renewed unless the
          company confirms termination. Either party may terminate this
          Agreement at any time upon written notice to the other party. However,
          the terminating party must provide a minimum of three (3) months
          written notice prior to the effective termination date.
        </Text>
        <Text style={styles.footer}>
          Non-binding Service Provider Contract between CarryBags Limited UK and
          Retailer
        </Text>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={DEFAULT_BACKGROUND_PATH} style={styles.pageBackground} />
        <Text style={styles.contractTitle}>CONTRACT</Text>
        <Text style={styles.textBody}>
          This notice shall be sent via registered mail or email with
          confirmation of receipt to the other party's address as listed in this
          Agreement. Upon the effective termination date, {retailerName} shall
          immediately discontinue the use of CarryBags' services and any
          associated intellectual property. CarryBags shall return any property
          belonging to {retailerName} within thirty (30) days of the termination
          date.
        </Text>
        <Text style={styles.textBody}>
          Representations and Warranties: Each party represents and warrants
          that it has the full right, power and authority to enter and perform
          this Agreement.
        </Text>
        <Text style={styles.textBody}>
          Confidentiality: The parties acknowledge that in connection with this
          Agreement, each may have access to certain confidential or proprietary
          information of the other party. Each party agrees to hold such
          information in strict confidence and not to disclose such information
          to any third party, except as required by law or with prior written
          consent of the other party.
        </Text>
        <Text style={styles.textBody}>
          Marketing and Promotion: {retailerName} hereby grants CarryBags a
          non-exclusive, worldwode, royalty-free license to use {retailerName}'s
          name, trademarks and products for the purpose of marketing and
          promotion of the Services provided under this Agreement.
        </Text>
        <Text style={styles.textBody}>
          CarryBags may use {retailerName}'s name and products in any marketing
          or promotional materials, including but not limited to, social media
          posts, press releases, and advertisements. CarryBags shall not make
          any false or misleading statements or representations about{" "}
          {retailerName} or its products.
        </Text>
        <Text style={styles.textBody}>
          If {retailerName} does not wish to be featured in any marketing or
          promotional materials, it shall provide written notice to CarryBags at
          least thirty (30) days prior to the launch of the marketing or
          promotional campaign.
        </Text>
        <Text style={styles.textBody}>
          Intellectual Property: CarryBags retains all intellectual property
          rights in any work product created in connection with the Services,
          including but not limited to the shopping bags and software solution.{" "}
          {retailerName} shall have a limited, non-exclusive, non-transferrable
          license to use CarryBags' intellectual property solely for the purpose
          of receiving the Services under this Agreement.
        </Text>
        <Text style={styles.textBody}>
          Limitation of Liability: Neither party shall be liable to the other
          for any indirect, special, incidental, consequential or punitive
          damages arising out of or in connection with this Agreement,
          regardless of whether such damages were forseeable or whether either
          party has been advised of the possibility of such damages.
        </Text>
        <Text style={styles.textBody}>
          Logistic Partners: {retailerName} shall comply with the requirements
          of CarryBags' logistic partners for the fulfillment of the Services
          provided under this Agreement. {retailerName} shall provide all
          necessary information and assistance to CarryBags and its logistic
          partners to ensure timely and accurate delivery of shopping bags to
          the desired destination.
        </Text>
        <Text style={styles.footer}>
          Non-binding Service Provider Contract between CarryBags Limited UK and
          Retailer
        </Text>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={DEFAULT_BACKGROUND_PATH} style={styles.pageBackground} />
        <Text style={styles.contractTitle}>CONTRACT</Text>
        <Text style={styles.textBody}>
          If {retailerName} fails to comply with the requirements of CarryBags'
          logistic partners, {retailerName} shall be liable for any delay, loss
          or damage arising from such non-compliance.
        </Text>
        <Text style={styles.textBody}>
          Governing Law and Jurisdiction: This Agreement shall be governed by
          and construed in accordance with the laws of {country}, without giving
          effect to any choice of law or conflict of law provisions. Any dispute
          arising out of or in connection with this Agreement shall be resolved
          exclusively by the courts of {country}.
        </Text>
        <Text style={styles.textBody}>
          Safekeeping of Shopping Bags: {retailerName} shall be solely
          responsible for the safekeeping of shopping bags collected from
          CarryBags' users until the arrival of CarryBags' logistic partner to
          collect them with a legitimate pickup code. {retailerName} shall
          ensure that shopping bags are kept in a secure location within the
          store, protected from theft or damage.
        </Text>
        <Text style={styles.textBody}>
          If any shopping bags are lost, stolen or daamaged while in{" "}
          {retailerName}'s possession, {retailerName} shall be liable for any
          resulting loss or damage. {retailerName} shall indemnify and hold
          CarryBags and its logistic partners harmless from any claims or
          damages arising from {retailerName}'s failure to properly safeguard
          shopping bags collection from CarryBags' users.
        </Text>
        <Text style={styles.textBodyWithBottomMargin}>
          Entire Agreement: This Agreement constitutes the entire agreement
          between the parties with respect to the subject matter hereof and
          supersedes all prior and contemporaneous communications and agreements
          between the parties, whether oral or written.
        </Text>
        <Text style={styles.textBody}>
          Amendments: This Agreement may be amended or modified only in writing
          signed by both parties.
        </Text>
        <Text style={styles.textBodyWithBottomMargin}>
          IN WITNESS WHEREOF, the parties have executed this Agreement as of the
          date first written above.
        </Text>
        <Text style={styles.textBodyWithBottomMargin}>
          CarryBags Limited Company and {retailerLegalName}
        </Text>
        <View style={styles.signingArea}>
          <Text style={styles.signingText}>BY {retailerLegalName}</Text>
          <Text style={styles.signingText}>BY CARRYBAGS LIMITED UK</Text>
        </View>
        <Text style={styles.signingText}>NAME {representativeName}</Text>
        <Text style={styles.footer}>
          Non-binding Service Provider Contract between CarryBags Limited UK and
          Retailer
        </Text>
      </View>
    </Page>
  </Document>
);
