export const env = {
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  firebaseMeasmurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};
