import { useEffect, useState } from "react";
import { useTranslate, useSelect } from "@pankod/refine-core";
import {
  Form,
  Input,
  Row,
  Select,
  Col,
  Button,
  Space,
} from "@pankod/refine-antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IUser, IStore, IProduct } from "interfaces";
import { Dates } from "components";
import QRCode from "react-qr-code";

export const FormList = ({ formProps, type }: any) => {
  const t = useTranslate();
  const users = useSelect<IUser>({
    resource: "users",
  });

  const stores = useSelect<IStore>({
    resource: "stores",
  });

  const { options, onSearch } = useSelect<IProduct>({
    resource: "products",
    onSearch: (value) => [
      // {
      //     field: "store.id",
      //     operator: "eq",
      //     value,
      // }
    ],
  });

  useEffect(() => {
    onSearch(formProps.form.getFieldsValue().store);
  }, [formProps.form.getFieldsValue().store]);

  // antd gives us an 'add' and 'remove' function
  // for changing the number of bags/bar codes.
  // We want to synchronise this with the form,
  // so we pass the function and then set the form afterwards.
  const addBag = (fields: Array<any>, add: any) => {
    add();
    formProps.form.setFieldValue("bags", fields.length + 1);
  };

  const removeBag = (fields: Array<any>, name: any, remover: any) => {
    remover(name);
    formProps.form.setFieldValue("bags", fields.length - 1);
  };

  const orderNumber =
    `${formProps.form.getFieldValue("orderNumber")}` || "loading";

  const isPaid = formProps.form.getFieldValue("isPaid");

  return (
    <Row gutter={[64, 0]} wrap>
      <Col xs={24} lg={6}>
        <Form.Item
          label={t("stores.fields.user")}
          name="user"
          rules={
            [
              // {
              //     required: true,
              //     message: 'User is required!'
              // },
            ]
          }
        >
          <Select disabled={type == "edit"}>
            <option value={""}>Select User</option>
            {users?.options?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("stores.fields.store")}
          name="store"
          rules={[
            {
              required: true,
              message: "Store is required!",
            },
          ]}
        >
          <Select disabled>
            {stores?.options?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Form.Item>
        {type == "edit" && (
          <Form.Item label={"QR Code"}>
            <QRCode value={orderNumber} size={150} />
          </Form.Item>
        )}
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          label={"Service Type"}
          name="serviceType"
          rules={[
            {
              required: true,
              message: "Service Type required!",
            },
          ]}
        >
          <Select
            disabled={formProps?.initialValues?.isPaid}
            options={[
              { label: "Normal", value: "normal" },
              { label: "Express", value: "express" },
            ]}
          />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="orderStatusArray">
          <Input />
        </Form.Item>
        <Form.Item label={"Employee ID"} name="employeeID" rules={[]}>
          <Input disabled={type == "edit"} />
        </Form.Item>
        <Form.Item
          label={"Order Date"}
          name="createDate"
          rules={[
            {
              required: true,
              message: "Order Date required!",
            },
          ]}
        >
          <Dates
            disabled={type == "edit"}
            name={"createDate"}
            formProps={formProps}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name="bags">
          <Form.Item
            label={"Bag codes"}
            name="bagCodes"
            rules={[{ required: true, message: "Bag codes is required!" }]}
          >
            <Form.List name="bagCodes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                    >
                      <Form.Item {...restField} name={[name, "bagCodes"]}>
                        <Input
                          placeholder="Barcode"
                          disabled={type === "edit" && isPaid}
                          addonAfter={
                            <MinusCircleOutlined
                              disabled={type === "edit"}
                              onClick={() => removeBag(fields, name, remove)}
                            />
                          }
                        />
                      </Form.Item>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => addBag(fields, add)}
                      disabled={type === "edit" && isPaid}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Bag
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form.Item>
      </Col>
    </Row>
  );
};
