import {
  IResourceComponentsProps,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import { Form, SaveButton, useForm } from "@pankod/refine-antd";
import { FormList } from "../stores/form";
import { authProvider } from "authProvider";

export const SignUp: React.FC<IResourceComponentsProps> = () => {
  const { Link } = useRouterContext();
  const { formProps } = useForm<any>({});
  const { list } = useNavigation();

  const onSuccess = async (values: any) => {
    await (authProvider as any).register(values);
    list("orders");
  };

  const formView = () => (
    <Form
      {...formProps}
      onFinish={(values) => onSuccess(values)}
      layout="vertical"
      initialValues={{ phoneCode: "+44" }}
    >
      <FormList formProps={formProps} type="create" />
      <Form.Item style={{ textAlign: "right" }}>
        <Link to="/login" style={{ marginRight: 10 }}>
          Login
        </Link>
        <SaveButton htmlType="submit" type="primary">
          Sign Up
        </SaveButton>
      </Form.Item>
    </Form>
  );

  return (
    <div
      style={{
        width: "90%",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Link to="/">
        <img src="/images/logo.png" alt="Logo" width="200" />
      </Link>
      <div
        style={{
          padding: 20,
          textAlign: "left",
          border: "solid 1px #ccc",
          borderRadius: 10,
          margin: "0 auto",
        }}
      >
        {formView()}
      </div>
    </div>
  );
};
